.horizontal-carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden; /* Oculta las partes fuera del contenedor */
    display: flex;
    align-items: center;
}

.horizontal-scroll {
    padding-top: 5%;
    display: flex;
    gap: 10px;
    overflow-x: auto;
    scroll-behavior: smooth; /* Habilita el desplazamiento suave */
    white-space: nowrap;
    scrollbar-width: none; /* Oculta scrollbar en Firefox */
}

.horizontal-scroll::-webkit-scrollbar {
    display: none; /* Oculta scrollbar en Chrome y Safari */
}

.horizontal-item {
    flex: 0 0 auto;
    width: 20%; /* Ajusta el ancho de cada imagen */
    transition: transform 0.3s ease; /* Suaviza el efecto de escala */
}

.horizontal-item:hover {
    transform: scale(1.1); /* Escala la imagen ligeramente al pasar el mouse */
    z-index: 5; /* Asegura que la imagen esté por encima de las demás */
}

.horizontal-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    transition: border 0.3s ease; /* Añade un efecto en el borde */
}

.horizontal-item:hover .horizontal-image {
    /* border: 2px solid #000;  */
}

.horizontal-arrow {
    position: absolute;
    top: 56%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    font-size: 20px;
}

.horizontal-arrow.left {
    left: 10px;
}

.horizontal-arrow.right {
    right: 10px;
}

.horizontal-arrow:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
    .horizontal-item {
        width: 45%;
    }

    .horizontal-arrow {
        top: 45%;
    }
}
