/* banner */

.banner {
    height: 47vh;
    min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* background-color: red; */
}

/* Carousel */

.carousel-item {
    height: 100vh;
    min-height: 250px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* background-color: red; */
}

.carousel-item2 {
    height: auto !important;
    min-height: 250px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* background-color: red; */
}

/* FinCarousel */

.imagen-fondo-index {
    height: auto;
    /* min-height: 225px; */
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/***********Texto sobre Imagen***************/
.cont_img {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.texto-encima {
    position: absolute;
    top: 10px;
    left: 10px;
}

.text_cent_img {
    position: absolute;
    top: 40.5%;
    left: 15.5%;
    right: 15.5%;
    /* transform: translate(-40%, -40%); */
    text-align: center;
    /* background-color: rgba(160, 158, 158, 0.384);
    padding: 2%; */
}

h1.tit-sob-img {
    font-family: 'Fredoka One', cursive;
    font-weight: bold !important;
    letter-spacing: 1px !important;
    font-size: calc(1.3em + 2.5vw);
    color: #3d641c;
    /* color: #3d641c; */
    /* border: black; */

}

/*Imagen 2*/



.text_cent_img2 {
    position: absolute;
    top: 55%;
    left: 30.5%;
    right: 30.5%;
    /* transform: translate(-50%, -50%); */
}

p.tit-sob-img2 {
    font-family: "lato", sans-serif !important;

    /* font-weight: bold !important;*/
    letter-spacing: 7px !important;
    font-size: calc(0.3em + 0.4vw);

}

@media (min-width:320px) {

    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    p.tit-sob-img2 {
        font-size: calc(0.5em + 0.7vw);
        letter-spacing: 1px !important;

        /* padding-bottom: 18vh; */
    }
}

@media (min-width:480px) {
    /* smartphones, Android phones, landscape iPhone */

    p.tit-sob-img2 {
        font-size: calc(0.5em + 0.7vw);
        letter-spacing: 10px !important;

        /* padding-bottom: 18vh; */
    }


}

@media (min-width:600px) {

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    p.tit-sob-img2 {
        font-size: calc(0.5em + 0.7vw);
        letter-spacing: 10px !important;

        /* padding-bottom: 18vh; */
    }
}

@media (min-width:801px) {
    p.tit-sob-img2 {
        font-size: calc(0em + 0vw);
    }
}

@media (min-width:1025px) {
    p.tit-sob-img2 {
        font-size: calc(0.5em + 0.7vw);
    }
}

@media (min-width:1281px) {
    p.tit-sob-img2 {
        font-size: calc(0.5em + 0.7vw);
    }
}

/*Pantalla dividida*/

.pantalla_dividida {
    position: absolute;

    left: 0%;
    right: 0.0%;
    /* transform: translate(-40%, -40%); */
    text-align: end;
    justify-content: center;
    background-color: rgba(68, 62, 62, 0);
    padding: 2%;
}

.cont_img_dividida {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.texto-encima_dividida {
    position: absolute;
    top: 10px;
    left: 10px;
}

.text_cent_img_dividida {
    position: absolute;
    top: 25%;
    left: 40%;
    right: 0%;
    /* transform: translate(-40%, -40%); */
    text-align: center;
    /* background-color: rgba(194, 16, 16, 0.384); */
    padding: 2%;
}

h1.tit-sob-img_dividida {
    font-family: 'Roboto Condensed' !important;
    font-weight: bold !important;
    letter-spacing: 2px !important;
    font-size: calc(1.9em + 2.1vw);
    color: white;
    /* border: black; */

}





/***********finTexto sobre Imagen***************/

/*********cajon de letras *********/

.header-tit {
    height: 70vh;
    min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* background-color: red; */
}

/* fin de cajon con letras */

/* Div con fondo de imagen */

.formulario {
    padding-top: 20Vh;
}

.img-login {
    padding-top: 9vh;
    padding-left: 3vw;

}

.fondo {
    background-image: url(banner.jpg);
    background-size: cover;
}

/* Div con fondo de imagen */

/* tipografia */
.camping-letters {
    font-family: 'Fredoka One', cursive;
}

p {
    font-family: 'Cabin' !important;

}




/* tipografia */


/* *****PANTALLAS ***** */

.pantalla-peque {
    display: none;
}

.pantalla-grande {
    display: block;
}

@media (max-width:801px) {
    .pantalla-peque {
        display: block;
    }

    .pantalla-grande {
        display: none;
    }

}

@media (max-width:801px) {
    .sell-equipment-card-body {
        padding: 0.25rem;
    }

}

/* ############INDEXSCREEN################## */
@media (max-width:991px) {
    .img-info {
        display: none;
    }

}

.btn-rs {
    /* position: fixed; */
    width: 55px;
    height: 55px;
    /* line-height: 55px; */
    /* bottom: 30px;
    right: 30px; */
    /* background: #0df053; */
    color: #fff;
    /* border-radius: 50px; */
    text-align: center;
    font-size: 4.5rem;
    /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3); */
    /* z-index: 100; */
}




.btn-rs:hover {
    text-decoration: none;
    color: #3d641c;
    /* background: #fff; */

}

@media (max-width:991px) {
    .btn-rs {
        font-size: 3rem !important;
    }

}

/* ###############MONTHLYTOURSCREEN################# */
.current-month-card {
    padding: 4vh
}

@media (max-width:991px) {
    .current-month-card {
        padding: 3vh;
    }

}

@media (max-width:415px) {
    .current-month-card {
        padding: 0.1vh;
    }

}

.next-month-card {
    padding: 4vh
}

@media (max-width:991px) {
    .next-month-card {
        padding: 3vh;
    }

}

@media (max-width:415px) {
    .next-month-card {
        padding: 0.1vh;
    }

}

/* ############################ALL TOU SCREEN################################ */

.img-list-at {
    padding: '0.2rem'
}

@media (max-width:991px) {
    .img-list-at {
        padding: '5rem'
    }

    .card-body {
        flex: 1 1 auto;
        min-height: 0px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0rem;
        padding-bottom: 0.5rem;
    }

    .t-name {
        text-align: center !important;
    }

    .t-destiny {
        text-align: center !important;
    }

}




/* ######################################MONTHLY TOUR SCREEN####################################### */

.sm-screen {

}

@media (max-width:574px) {
    .sm-screen {
        padding-right: 2vw;
        padding-left:  2vw;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .sm-screen {
        padding-right: 3vw;
        padding-left: 3vw;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .sm-screen {
        padding-right: 2vw;
        padding-left: 2vw;
    }
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.blink {
    animation: blink 1s infinite;
}